import React from 'react';
import quranHead2 from '../../img/head4.jpg';

const QuranNums = (props) => {
    return (
        <div id="quranHead2" style ={ { backgroundImage: "url("+quranHead2+")" } }>
            <div>سوره مبارکه {props.sureName} آیه {props.ayeNum}</div>
        </div>
    );
}

export default QuranNums;