import React from 'react';

import qurantop from '../../img/pagetop2.jpg';
import quranBack from '../../img/page2.jpg';
import quranFoot from '../../img/head3.jpg';
import quranFoot2 from '../../img/page3.jpg';
import waitingImg from '../../img/waiting.gif';

class Quran extends React.Component {

    state = {
        Aye: [],
        address: '',
        height: 0,
        isLoading: true
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        // Get Text
        let transl = localStorage.getItem('transl');
        let qari = (localStorage.getItem('qari')) ? localStorage.getItem('qari') : 'Hani_Rifai_64kbps';
        fetch('https://backend.ba-ham.com/quran/getaye/' + transl)
        .then(res => res.json())
        .then((data) => {
            let verse = ('00' + data.verse).slice(-3);
            let sure = ('00' + data.sureNum).slice(-3);
            this.props.setQHead(data.sure, data.verse);
            this.setState({ 
                Aye: data,
                address: `http://www.everyayah.com/data/${qari}/${sure}${verse}.mp3`
            });
        })
        .catch(console.log);
    }

    playAudio() 
    {
        const audioPromise = this.audio.play()
        if (audioPromise !== undefined) {
          audioPromise
            .then(_ => {
              // autoplay started
            })
            .catch(err => {
              // catch dom exception
              console.info(err)
            })
        }
    }

    stopAudio() 
    {
        this.audio.pause();
        this.audio.currentTime = 0;
    }

    componentDidUpdate()
    {
        var h;
        if(this.divRef2.clientHeight > this.divRef.clientHeight)
            h = this.divRef2.clientHeight + 150;
        else
            h = this.divRef.clientHeight + 150;
        
        if(this.state.height !== h)
        {
            this.setState({ 
                height: h,
                isLoading: false
            });

            // Get Audio
            if(this.state.address !== '')
            {
                console.log(this.state.address);
                
                this.audio = new Audio(this.state.address);
                this.audio.load();
                this.playAudio();
            }
        }
    }

    goBack()
    {
        this.props.goOn();
        this.stopAudio();
    }

    render()
    {   
        return (
            <div>
                <div id="waiting" className={this.state.isLoading ? '' : 'hidden'}>
                    <img alt="" src={waitingImg} />
                </div>
                <div id="quranHead3" style ={ { backgroundImage: "url("+qurantop+")" } }>
                    <div id="qStart" className="noselect">*بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ*</div>
                </div>
                <div id="quran" style ={ { backgroundImage: "url("+quranBack+")", minHeight: this.state.height } }>
                    <div id="qBody">
                        <div id="arab" className="noselect" ref={element => this.divRef = element}>{this.state.Aye.arabic}</div>
                        <div id="tran" className="noselect" ref={element => this.divRef2 = element}>{this.state.Aye.persian}</div>
                    </div>
                </div>
                <img id="quranFoot2" alt="" src={quranFoot2} />
                <div id="qEnd" className="noselect" style ={ { backgroundImage: "url("+quranFoot+")" } }>
                        <div onClick={() => {this.goBack()}}>بازگشت</div>
                        <div onClick={() => {this.playAudio()}}>تکرار</div>
                        <div onClick={()=> window.open("http://ba-ham.com", "_blank")}>باهم</div>
                </div>
            </div>
        );
    }
};

export default Quran;