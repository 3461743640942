import React from 'react';
import PageBody from './Body/Greeting.js'

//import quranBackground from './img/page.png';

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component
{
    render()
    {
        return (
            <div className="app">
                <PageBody />
            </div>
        );
    }
}

export default App;