import React from 'react';
import Quran from './Quran.js';
import Def from './Default.js';
import QuranNums from './QuranNums.js';
import quranHead from '../../img/quranHead.jpg';

class Greeting extends React.Component {

    state = {
        stat: 0,
        ayeNum: 0,
        sureName: '',
        hideWait: false
    };

    goOn = () => {
        this.setState( prevState => {
            if(this.state.stat===0)
                return {
                    stat: 1
                }; 
            else
                return {
                    stat: 0
                }; 
        });
    }

    setQHead = (sure, verse) => {
        this.setState(
            { 
                ayeNum: verse,
                sureName: sure
            }
        );
    }

    render()
    {
        if (this.state.stat === 1)
            return <div>
                <img id="quranHead" alt="" src={quranHead} />
                <QuranNums ayeNum={this.state.ayeNum} sureName={this.state.sureName} />
                <Quran setQHead={this.setQHead} goOn={this.goOn} />
            </div>;
        else
            return <div><img id="quranHead" alt="" src={quranHead} />
            <Def goOn={this.goOn} /></div>;
    }
}

export default Greeting;