import React from 'react';

import quranHead from '../../img/pagetop2.jpg';
import quranBack from '../../img/page2.jpg';
import quranFoot2 from '../../img/page3.jpg';

class Defaults extends React.Component 
{   
    state = {
        count: 0,
        vCount: 0
    };

    transl = (localStorage.getItem('transl')) ? localStorage.getItem('transl') : 'qom';
    qari = (localStorage.getItem('qari')) ? localStorage.getItem('qari') : 'Hani_Rifai_64kbps';

    changeQari = (event) => 
    {
        let qariL = event.target.value;
        this.qari = qariL;
        localStorage.setItem('qari', qariL);
    };

    changeTransl = (event) => {
        let translL = event.target.value;
        this.transl= translL;
        localStorage.setItem('transl', translL);
    };

    componentDidMount() 
    {
        this.showCount();
    }

    showCount()
    {
        fetch('https://backend.ba-ham.com/quran/stats')
        .then(res => res.json())
        .then((data) => {
            this.setState({ 
                count: data.count,
                vCount: data.vCount
            });
        });
    }

    render()
    {
        return (
            <div>
            <img id="quranHead4" alt="" src={quranHead} />
            <div id="quran" style ={ { backgroundImage: "url("+quranBack+")" } }>
                <div id="txtHead">به نام خداوند بخشاینده مهربان</div>
                <div id="txt">
                <p>
                    ماه رمضان امسال، شبکه اجتماعی باهم ، به نیت تک تک آرزوهای خوب کاربرانش اقدام به برگزاری ختم گروهی قرآن نموده است. در صورتی که تمایل دارید در این طرح شرکت کنید کافی است کلید شروع را فشار دهید.
                </p>
                    التماس دعا
                </div>
                <form className="form-group">
                    <label className="badge badge-warning">قاری</label>
                    <select defaultValue={this.qari}
                            onChange={this.changeQari}
                            className="form-control">
                        <option value="Hani_Rifai_64kbps">هانی ریفای</option>
                        <option value="Abdurrahmaan_As-Sudais_64kbps">شیخ سدیس</option>
                        <option value="Nasser_Alqatami_128kbps">ناصر القطامي</option>
                        <option value="Abdul_Basit_Mujawwad_128kbps">عبدالباسط</option>
                        <option value="MaherAlMuaiqly128kbps">ماهر المقیلی</option>
                    </select>
                    <label className="badge badge-warning">ترجمه</label>
                    <select onChange={this.changeTransl}
                            defaultValue={this.transl}
                            className="form-control">
                        <option value="qom">استاد مهدی الهی قمشه‌ای</option>
                        <option value="mak">آیت الله مکارم شیرازی</option>
                    </select>
                    <button type="button" className="btn btn-success" onClick={() => {this.props.goOn()}}>آغاز</button>
                </form>
                <label className="badge badge-warning">آمار قرآئت تا این لحظه</label>
                <div className="bg-inline">
                    <label className="badge badge-secondary">قرآئت کامل</label>
                    <label className="badge badge-secondary">آیات قرآئت اخیر</label>
                </div>
                <div className="bg-inline">
                    <label className="badge badge-secondary">{this.state.count}</label>
                    <label className="badge badge-secondary">{this.state.vCount}</label>
                </div>
            </div>
            <img id="quranFoot2" alt="" src={quranFoot2} />
            </div>
        );
    }
};

export default Defaults;
